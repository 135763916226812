import { injectable, inject } from "inversify";
import { CONTAINER_TYPES } from "./../infrastructure/ioc/container-types";
import ServiceInterface from "./service-interface";
import { ApiInterface } from "../infrastructure/api/interfaces/api-interface";
import { Api } from "../infrastructure/api/api";
import DfStore from "@/vue/domain/store/df-store";
import DfProvince from "@/vue/domain/store/df-province";
import DfDayHoursActivityRange from "@/vue/domain/store/df-day-hours-activity-range";
import DfBrand from "@/vue/domain/brand/df-brand";
import DfPromotion from "@/vue/domain/promotion/df-promotion";
import DfDayOfWeek from "@/vue/domain/promotion/df-days-of-week";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfProduct from "@/vue/domain/product/df-product";
import DfCode from "@/vue/domain/brand/df-code";
import DfPaging from "@/vue/domain/paging/df-paging";
import DfContent from "@/vue/domain/content/df-content";
import DfSchedulingStatus from "@/vue/domain/content/df-scheduling-status";
import DfProperty from "@/vue/domain/content/df-property";
import DfContentType from "@/vue/domain/content/df-content-type";
import DfGroup from "@/vue/domain/group/df-group";
import DfCoordinates from "@/vue/domain/store/df-coordinates";
import DfDateRange from "@/vue/domain/store/df-date-range";
import DfNewsletterSubscribeStatus from "@/vue/domain/newsletter/df-newsletter-subscribe-status";
import DfNewsletterUnsubscribeStatus from "@/vue/domain/newsletter/df-newsletter-unsubscribe-status";
import DfUser from "@/vue/domain/user/df-user";

@injectable()
class Service implements ServiceInterface {
  @inject(CONTAINER_TYPES.ApiInterface)
  private api: ApiInterface;

  public async activeUser(params: any): Promise<DfStore> {
    return Promise.resolve(await this.api.activeUser(params)).then((response: any) => {
      return response;
    });
  }

  public async changeEmail(params: any): Promise<any> {
    return Promise.resolve(await this.api.changeEmail(params)).then((response: any) => {
      return response;
    });
  }

  public async changePassword(params: any): Promise<any> {
    return Promise.resolve(await this.api.changePassword(params)).then((response: any) => {
      return response;
    });
  }

  public async deleteAccount(params: any): Promise<any> {
    return Promise.resolve(await this.api.deleteAccount(params)).then((response: any) => {
      return response;
    });
  }

  public async downloadFile(params: any): Promise<DfStore> {
    return Promise.resolve(await this.api.downloadFile(params)).then((blob: any) => {
      return blob;
    });
  }

  public async login(): Promise<any> {
    const formData = new FormData();
    formData.append("grant_type", "client_credentials");
    formData.append("scope", "read write");
    return Promise.resolve(await this.api.login(formData)).then((tokenData: any) => {
      Utils.localStorageSetItem(Utils.BEARER_ACCESS_TOKEN_KEY, tokenData.access_token);
      Utils.localStorageSetItem(Utils.BEARER_REFRESH_TOKEN_KEY, tokenData.refresh_token || "");
      return tokenData;
    });
  }

  public async logout(): Promise<any> {
    const formData = new FormData();
    formData.append("grant_type", "client_credentials");
    formData.append("scope", "read write");
    return Promise.resolve(await this.api.logout(formData)).then((tokenData: any) => {
      return true;
    });
  }

  public async userLogin(formData: FormData): Promise<any> {
    formData.append("grant_type", "password");
    formData.append("scope", "read write");
    return Promise.resolve(await this.api.login(formData)).then((tokenData: any) => {
      Utils.localStorageSetItem(Utils.BEARER_ACCESS_TOKEN_KEY, tokenData.access_token);
      Utils.localStorageSetItem(Utils.BEARER_REFRESH_TOKEN_KEY, tokenData.refresh_token || "");
      return tokenData;
    });
  }

  public async getStore(params: any): Promise<DfStore> {
    return Promise.resolve(await this.api.getStore(params)).then((storeData: any) => {
      return storeData ? this.createStore(storeData) : null;
    });
  }

  public async getStores(params: any): Promise<any> {
    return Promise.resolve(await this.api.getStores(params)).then((response: any) => {
      const storesData: Array<any> = params.paging ? response.elements : response;

      return {
        stores: storesData.map((storeData: any) => this.createStore(storeData)),
        paging: params.paging ? this.createPaging(response) : null,
      };
    });
  }

  public async getBrand(): Promise<DfBrand> {
    return Promise.resolve(await this.api.getBrand()).then((brandData: any) => {
      return this.createBrand(brandData);
    });
  }

  public async getIp(): Promise<any> {
    return Promise.resolve(await this.api.getIp()).then((data: any) => {
      return data;
    });
  }

  public async getPromotions(params: any): Promise<Array<DfPromotion>> {
    return Promise.resolve(await this.api.getPromotions(params.storeAlias)).then((promotionsData: Array<any>) => {
      promotionsData.sort((promoA, promoB) => (promoA.priority <= promoB.priority ? 1 : -1));

      return promotionsData.map((promotionData: any) => {
        return this.createPromotion(promotionData);
      });
    });
  }

  public async getProducts(params: any): Promise<any> {
    return Promise.resolve(await this.api.getProducts(params.promotionAlias, params.storeAlias, params.paging, params.query, params.contentAliases, params.customFilters)).then((response: any) => {
      const products: Array<DfProduct> = response.elements.map((productData: any) => {
        return this.createProduct(productData);
      });

      return {
        products: products,
        paging: this.createPaging(response),
      };
    });
  }

  public async getBrandContents(params: any): Promise<Array<DfContent>> {
    return Promise.resolve(await this.api.getBrandContents(params.typeCode)).then((contents: any) => {
      contents.sort((contentA: any, contentB: any) => (contentA.priority <= contentB.priority ? 1 : -1));
      return contents.map((contentData: any) => {
        return this.createContent(contentData);
      });
    });
  }

  public async getStoreContents(params: any): Promise<Array<DfContent>> {
    return Promise.resolve(await this.api.getStoreContents(params.storeAlias, params.typeCode)).then((contents: any) => {
      contents.sort((contentA: any, contentB: any) => (contentA.priority <= contentB.priority ? 1 : -1));
      return contents.map((contentData: any) => {
        return this.createContent(contentData);
      });
    });
  }

  public async getStoreOpenings(params: any): Promise<DfDateRange> {
    return Promise.resolve(await this.api.getStoreOpenings(params.storeAlias, params.daysInterval)).then((openings: any) => {
      return openings.map((opening: any) => {
        return this.createDateRange(opening);
      });
    });
  }

  public async getStoreClosures(params: any): Promise<DfDateRange> {
    return Promise.resolve(await this.api.getStoreClosures(params.storeAlias, params.daysInterval)).then((closures: any) => {
      return closures.map((closure: any) => {
        return this.createDateRange(closure);
      });
    });
  }

  public async getPromotionContents(params: any): Promise<Array<DfContent>> {
    return Promise.resolve(await this.api.getPromotionContents(params.promotionAlias, params.storeAlias, params.typeCode)).then((contents: any) => {
      contents.sort((contentA: any, contentB: any) => (contentA.priority <= contentB.priority ? 1 : -1));
      return contents.map((contentData: any) => {
        return this.createContent(contentData);
      });
    });
  }

  public async getProduct(params: any): Promise<any> {
    return Promise.resolve(await this.api.getProduct(params.promotionAlias, params.storeAlias, params.productAlias)).then((productData: any) => {
      return productData ? this.createProduct(productData) : null;
    });
  }

  public async getProductByCode(params: any): Promise<any> {
    return Promise.resolve(await this.api.getProductByCode(params.promotionAlias, params.storeAlias, params.productCode)).then((productData: any) => {
      return productData ? this.createProduct(productData) : null;
    });
  }

  public async getProductByExternalData(params: any): Promise<any> {
    return Promise.resolve(await this.api.getProductByExternalData(params)).then((productData: any) => {
      return productData || null;
    });
  }

  public async getProductByLink(params: any): Promise<any> {
    return Promise.resolve(await this.api.getProductByLink(params)).then((productData: any) => {
      return productData ? this.createProduct(productData) : null;
    });
  }

  public async getProductsList(params: any): Promise<Array<DfProduct>> {
    return Promise.resolve(await this.api.getProductsList(params.promotionAlias, params.storeAlias, params.productsUniqueId)).then((products: any) => {
      return products.map((productData: any) => {
        return this.createProduct(productData);
      });
    });
  }

  public async getPromotionGroups(params: any): Promise<Array<DfGroup>> {
    return Promise.resolve(await this.api.getPromotionGroups(params.promotionAlias)).then((groupsData: any) => {
      return groupsData.map((groupData: any) => this.createGroup(groupData));
    });
  }

  public async getUser(): Promise<DfUser> {
    return Promise.resolve(await this.api.getUser()).then((userData: any) => {
      return this.createUser(userData);
    });
  }

  public async getUserContents(params: any): Promise<any> {
    return Promise.resolve(await this.api.getUserContents(params)).then((response: any) => {
      const userContents: Array<DfContent> = response.elements.map((contentData: any) => this.createContent(contentData));

      return {
        userContents: userContents,
        paging: this.createPaging(response),
      };
    });
  }

  public async getRegistrationData(): Promise<any> {
    return Promise.resolve(await this.api.getRegistrationData()).then((form: any) => {
      return {
        confirmPassword: form.confirmPassword,
        email: form.email,
        password: form.password,
        properties: form.properties.map((propertyData: any) => this.createProperty(propertyData)),
      };
    });
  }

  public async getProfileForm(): Promise<any> {
    return Promise.resolve(await this.api.getProfileForm()).then((form: any) => {
      return {
        confirmPassword: form.confirmPassword,
        email: form.email,
        password: form.password,
        properties: form.properties.map((propertyData: any) => this.createProperty(propertyData)),
      };
    });
  }

  public async recoveryPassword(params: any): Promise<any> {
    return Promise.resolve(await this.api.recoveryPassword(params)).then((response: any) => {
      return response;
    });
  }

  public async refreshToken(): Promise<any> {
    const formData = new FormData();
    formData.append("grant_type", "refresh_token");
    formData.append("refresh_token", Utils.localStorageGetItem(Utils.BEARER_REFRESH_TOKEN_KEY));
    formData.append("scope", "read write");
    const api: ApiInterface = new Api();
    return Promise.resolve(await api.refreshToken(formData)).then((refreshTokenData: any) => {
      Utils.localStorageSetItem(Utils.BEARER_ACCESS_TOKEN_KEY, refreshTokenData.access_token);
      Utils.localStorageSetItem(Utils.BEARER_REFRESH_TOKEN_KEY, refreshTokenData.refresh_token || "");

      api.setDefaultHeaders();
    });
  }

  public async resetPassword(params: any): Promise<any> {
    return Promise.resolve(await this.api.resetPassword(params)).then((response: any) => {
      return response;
    });
  }

  public async setProfileData(params: any): Promise<any> {
    return Promise.resolve(await this.api.setProfileData(params)).then((data: any) => {
      return data;
    });
  }

  public async setRegistrationData(params: any): Promise<any> {
    return Promise.resolve(await this.api.setRegistrationData(params)).then((data: any) => {
      return data;
    });
  }

  public async sendFormMail(params: any): Promise<any> {
    return Promise.resolve(await this.api.sendFormMail(params)).then((response: any) => {
      return response;
    });
  }

  public async subscribeNewsletter(params: any): Promise<DfNewsletterSubscribeStatus> {
    return Promise.resolve(await this.api.subscribeNewsletter(params)).then((response: any) => {
      return DfNewsletterSubscribeStatus.getEnum(response);
    });
  }

  public async unsubscribeNewsletter(params: any): Promise<DfNewsletterUnsubscribeStatus> {
    return Promise.resolve(await this.api.unsubscribeNewsletter(params)).then((response: any) => {
      return DfNewsletterUnsubscribeStatus.getEnum(response);
    });
  }

  public createStore(storeData: any): DfStore {
    const province: DfProvince = storeData.province ? new DfProvince(storeData.province.name, storeData.province.code) : null;
    const newStore: DfStore = new DfStore(storeData.identifier, storeData.alias, storeData.name, storeData.code);

    newStore.catchmentArea = storeData.catchmentArea;
    newStore.country = storeData.country;
    newStore.province = province;
    newStore.city = storeData.city;
    newStore.postalCode = storeData.postalCode;
    newStore.address = storeData.address;
    newStore.phone = storeData.phone;
    newStore.fax = storeData.fax;
    newStore.email = storeData.email;
    newStore.notes = storeData.notes;
    newStore.availableForCollect = storeData.availableForCollect;
    newStore.availableForDelivery = storeData.availableForDelivery;
    newStore.distance = storeData.distance;

    if (storeData.gpsCoordinates) {
      newStore.gpsCoordinates = new DfCoordinates(storeData.gpsCoordinates.latitude || 0, storeData.gpsCoordinates.longitude || 0);
    }

    if (storeData.openingTimes) {
      newStore.openingTimes = storeData.openingTimes.map((openingTime: any) => {
        return new DfDayHoursActivityRange(DfDayOfWeek.getEnum(openingTime.dayOfWeek), openingTime.startTime, openingTime.endTime, openingTime.additionalStartTime, openingTime.additionalEndTime);
      });
    }

    if (storeData.pickingTimes) {
      newStore.pickingTimes = storeData.pickingTimes.map((pickingTime: any) => {
        return new DfDayHoursActivityRange(DfDayOfWeek.getEnum(pickingTime.dayOfWeek), pickingTime.startTime, pickingTime.endTime, pickingTime.additionalStartTime, pickingTime.additionalEndTime);
      });
    }

    if (storeData.properties) {
      newStore.properties = storeData.properties.map((property: any) => {
        return this.createProperty(property);
      });
    }

    return newStore;
  }

  public createBrand(brandData: any): DfBrand {
    const properties: Array<any> = brandData.properties || [];
    return new DfBrand(brandData.identifier, brandData.alias, brandData.description, brandData.notes, properties);
  }

  public createPromotion(promotionData: any): DfPromotion {
    const daysOfWeek: Array<DfDayOfWeek> = promotionData.daysOfWeek.map((dayOfWeek: string) => {
      return DfDayOfWeek.getEnum(dayOfWeek);
    });

    const properties: Array<any> = promotionData.properties
      ? promotionData.properties.map((property: string) => {
          return property;
        })
      : [];

    return new DfPromotion(promotionData.identifier, promotionData.code, promotionData.alias, promotionData.description, promotionData.priority, promotionData.startDate, promotionData.endDate, promotionData.startTime, promotionData.endTime, daysOfWeek, properties, promotionData.hidden);
  }

  public createProduct(productData: any): DfProduct {
    const code: DfCode = new DfCode(productData.code.type, productData.code.value);

    const properties: Array<any> = productData.properties
      ? productData.properties.map((property: string) => {
          return property;
        })
      : [];

    return new DfProduct(productData.uniqueId, productData.alias, productData.description, code, properties, productData.groups || []);
  }

  public createPaging(pagingData: any): DfPaging {
    return new DfPaging(pagingData.numberOfElements, pagingData.totalElements, pagingData.totalPages, pagingData.size, pagingData.empty, pagingData.first, pagingData.last, pagingData.number);
  }

  public createContent(contentData: any): DfContent {
    const type: DfContentType = new DfContentType(contentData.type.code, contentData.type.name);

    const daysOfWeek: Array<DfDayOfWeek> = contentData.daysOfWeek.map((dayOfWeek: string) => {
      return DfDayOfWeek.getEnum(dayOfWeek);
    });

    const properties: Array<DfProperty> = contentData.properties.map((property: any) => {
      return this.createProperty(property);
    });

    return new DfContent(
      contentData.uniqueId,
      contentData.alias,
      contentData.promotion,
      type,
      contentData.name,
      contentData.priority,
      contentData.startDate,
      contentData.endDate,
      contentData.startTime,
      contentData.endTime,
      daysOfWeek,
      contentData.products,
      DfSchedulingStatus.getEnum(contentData.schedulingStatus),
      properties
    );
  }

  public createGroup(groupData: any): DfGroup {
    return new DfGroup(groupData.identifier, groupData.code, groupData.alias, groupData.name, groupData.products);
  }

  public createDateRange(dateData: any): DfDateRange {
    return new DfDateRange(dateData.name, dateData.note, dateData.startDate, dateData.startTime, dateData.additionalStartTime, dateData.endDate, dateData.endTime, dateData.additionalEndTime);
  }

  public createUser(userData: any): DfUser {
    return userData
      ? new DfUser(
          userData.logged,
          userData.email,
          userData.properties.map((propertyData: any) => this.createProperty(propertyData))
        )
      : null;
  }

  public createProperty(propertyData: any): DfProperty {
    const property: DfProperty = new DfProperty(propertyData.type, propertyData.code, propertyData.label, propertyData.values);
    property.required = propertyData.required || false;
    property.listOptions = propertyData.listOptions || [];
    property.minOccurrence = propertyData.minOccurrence || 1;
    property.maxOccurrence = propertyData.maxOccurrence || 1;
    property.readOnly = propertyData.readOnly || false;

    return property;
  }
}
export default Service;
